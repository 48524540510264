import { Fetcher } from '@remix-run/react'
import { useEffect, useState } from 'react'

/**
 * Custom hook that triggers a callback when a Fetcher completes loading.
 *
 * This hook tracks the Fetcher's state and invokes the callback when it
 * transitions from 'loading' to 'idle'. Even when a fetcher is submitted,
 * it will always transition through the 'loading' state before reaching
 * 'idle'.
 *
 * @param {() => void} callback - Function to call when loading is complete.
 * @param {Fetcher} fetcher - The Fetcher instance to monitor.
 */
export default function useOnFetcherLoaded(
  callback: () => void,
  fetcher: Fetcher,
): void {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (fetcher.state === 'loading') {
      setLoaded(true)
    } else if (loaded && fetcher.state === 'idle') {
      callback()
    }
  }, [fetcher.state])
}
